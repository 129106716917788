import styled from '@emotion/styled'

const Styles = styled.button`
  &:hover {
    transform: ${(props) => (props.disabled ? null : 'scale(1.1)')};
  }
  transition: ${(props) =>
    props.disabled ? null : 'transform 0.25s ease-out'};
`

interface SubmitProps {
  loading?: boolean
}

const LoadingGlyph = (props: any) => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
    <path d="M304 48c0 26.51-21.49 48-48 48s-48-21.49-48-48 21.49-48 48-48 48 21.49 48 48zm-48 368c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.49-48-48-48zm208-208c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.49-48-48-48zM96 256c0-26.51-21.49-48-48-48S0 229.49 0 256s21.49 48 48 48 48-21.49 48-48zm12.922 99.078c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48c0-26.509-21.491-48-48-48zm294.156 0c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48c0-26.509-21.49-48-48-48zM108.922 60.922c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.491-48-48-48z" />
  </svg>
)

export function Submit({ loading }: SubmitProps) {
  const loadingGlyphSize = 30

  return (
    <Styles
      type="submit"
      disabled={loading}
      style={{
        background: loading ? '#777' : 'rgb(0, 133, 245)',
        color: 'white',
        cursor: 'pointer',
        fontSize: 15,
        borderRadius: 6,
        border: 'none',
        fontWeight: 600,
        fontFamily: 'inherit',
        padding: 0,
        width: '25%',
        height: 47,
        position: 'relative',
      }}
    >
      <style>{`
@keyframes fullRotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
`}</style>
      <LoadingGlyph
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          width: loadingGlyphSize,
          height: loadingGlyphSize,
          marginLeft: -loadingGlyphSize / 2,
          marginTop: -loadingGlyphSize / 2,
          fill: 'white',
          userSelect: 'none',
          visibility: loading ? 'visible' : 'hidden',
          animation: 'fullRotation 0.5s steps(8) infinite',
        }}
      />
      <span
        style={{
          userSelect: loading ? 'none' : undefined,
          visibility: loading ? 'hidden' : 'visible',
        }}
      >
        Submit
      </span>
    </Styles>
  )
}
