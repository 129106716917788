import 'react-phone-number-input/style.css'
import PhoneInput, {
  DefaultInputComponentProps,
  Props,
} from 'react-phone-number-input'
import styled from '@emotion/styled'

export const defaultCountry = 'AU'

const Styles = styled(PhoneInput)`
  width: 75%;
  background: rgb(236, 236, 236);
  border-radius: 6px;
  & {
    .PhoneInputCountry {
      border-right: 1px solid hsla(0, 0%, 0%, 0.125);
      padding: 0 16px;
      margin: 0;
    }
    .PhoneInputInput {
      height: 100%;
      box-sizing: border-box;
      border: none;
      padding: 0 12px;
      background: transparent;
      color: rgb(46, 42, 37);
      font-size: 16px;
      font-family: inherit;
      font-weight: 600;
      &:focus {
        background: transparent;
        outline: none;
        transition: transform 0.25s ease-out;
      }
    }
  }
`

export default function Input({
  loading,
  ...props
}: Props<DefaultInputComponentProps> & { loading: boolean }) {
  return (
    <Styles
      {...props}
      readOnly={loading}
      defaultCountry={defaultCountry}
      placeholder="Enter your mobile number"
      countries={['AU', 'NZ']}
      addInternationalOption={false}
    />
  )
}
