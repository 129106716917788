import { StrictMode } from 'react'
import { render } from 'react-dom'
import PhoneNumber from './PhoneNumber'

const container = document.getElementById('app-download-sms')

render(
  <StrictMode>
    <PhoneNumber
      {...Object.assign(
        {
          endpoint: 'https://sniip-dev.com/twilio/sms/appDownload',
          sitekey: '6LfuQdweAAAAADBuJWHftY7pjeEqKFiYKg0Kn3_z',
        },
        container?.dataset
      )}
    />
  </StrictMode>,
  container
)
