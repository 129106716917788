import { useState, createRef, FormEvent, useReducer, useEffect } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import Input, { defaultCountry } from './Input'
import { Country, isPossiblePhoneNumber, Value } from 'react-phone-number-input'
import { Submit } from './Submit'
import { strictEqual } from 'assert'

const css = `
.grecaptcha-badge {
  visibility: hidden;
}
`

interface FormFields {
  countryCode: Country
  phoneNumber?: Value
}

type State = { formFields: FormFields } & (
  | {
      value: 'idle'
    }
  | {
      value: 'loading'
    }
  | {
      value: 'success'
      response: Response
    }
  | {
      value: 'error'
      errorMessage: string
    }
)

export default function PhoneNumber({
  endpoint,
  sitekey,
}: {
  endpoint: string
  sitekey: string
}) {
  const [state, setState] = useState<State>({
    value: 'idle',
    formFields: { countryCode: 'AU' },
  })

  useEffect(() => {
    if (window.location.hostname === 'localhost') {
      console.log(state)
    }
  })

  const recaptchaRef = createRef<ReCAPTCHA>()

  useEffect(() => {
    if (state.value === 'error') {
      recaptchaRef.current!.reset()
    }
  }, [state.value, recaptchaRef.current])

  const onSubmit = async (e: FormEvent) => {
    setState({ value: 'loading', formFields: state.formFields })
    e.preventDefault()

    if (state.formFields.phoneNumber == null) {
      return setState({
        value: 'error',
        formFields: state.formFields,
        errorMessage: 'A phone number is required.',
      })
    }

    if (
      !isPossiblePhoneNumber(
        state.formFields.phoneNumber,
        state.formFields.countryCode
      )
    ) {
      return setState({
        value: 'error',
        formFields: state.formFields,
        errorMessage: 'Phone number is invalid.',
      })
    }

    const captchaRequest = recaptchaRef.current!.executeAsync()
    const timeout = new Promise<never>((_, reject) =>
      setTimeout(() => reject(), 90000)
    )

    let result: string | null
    try {
      result = await Promise.race([captchaRequest, timeout])
      if (result == null) {
        throw null
      }
    } catch (e) {
      return setState({
        value: 'error',
        formFields: state.formFields,
        errorMessage:
          'An error occurred validating your request. Please try again later.',
      })
    }
    const captchaToken = result

    // await new Promise((resolve) => setTimeout(resolve, 5000))

    const body = new FormData()
    Object.entries<string>({
      ...state.formFields,
      captchaToken,
    }).forEach(([key, value]) => {
      body.set(key, value)
    })

    let response
    try {
      response = await fetch(endpoint, {
        method: 'POST',
        body,
      })

      if (!response.ok) {
        throw null
      }
    } catch (error) {
      return setState({
        value: 'error',
        formFields: state.formFields,
        errorMessage:
          'An error occurred submitting your request. Please try again later.',
      })
    }

    if (response.redirected) {
      window.location.href = response.url
    }
    setState({ value: 'success', formFields: state.formFields, response })
  }

  return (
    <div
      style={{
        height: 115,
      }}
    >
      {state.value !== 'success' && (
        <form
          onSubmit={onSubmit}
          style={{
            fontFamily: 'inherit',
          }}
        >
          <div
            style={{
              display: 'flex',
              width: '100%',
              fontSize: 16,
            }}
          >
            <Input
              loading={state.value === 'loading'}
              value={state.formFields.phoneNumber}
              onChange={(phoneNumber) => {
                setState(({ formFields, ...state }) => ({
                  ...state,
                  formFields: { ...formFields, phoneNumber },
                }))
              }}
              onCountryChange={(countryCode = defaultCountry) =>
                setState(({ formFields, ...state }) => ({
                  ...state,
                  formFields: { ...formFields, countryCode },
                }))
              }
              required
            />

            <Submit loading={state.value === 'loading'} />
          </div>

          <p
            style={{
              color: 'rgb(211, 47, 47)',
            }}
          >
            {state.value === 'error' ? state.errorMessage.toString() : '\xa0'}
          </p>
        </form>
      )}

      {state.value === 'success' ? (
        <p
          style={{
            color: 'rgb(47, 211, 47)',
          }}
        >
          Thanks. You should hear from us shortly.
        </p>
      ) : null}

      {/* <style>{css}</style> */}
      <ReCAPTCHA ref={recaptchaRef} size="invisible" {...{ sitekey }} />
      <style>
        {`
.PhoneInput--focus {
  box-shadow: inset 0 0 0 1px rgb(0 0 0 / 10%)
}
`}
      </style>
    </div>
  )
}
